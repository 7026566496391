/* src/preloader/preloader.css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 1s ease-in-out;
}

.preloader__deer {
  width: 150px;
  height: 150px;

  animation: blink 2s infinite;
}


@keyframes blink {
  0%, 100% {
    opacity: 1;

    
    
  }
  50% {
    opacity: 0.7;

  }

}





.loaded_hiding .preloader {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}


