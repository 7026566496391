.App {
  height: 100%;
}

.content-hidden {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.content-visible {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.icons-container {
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  gap: 20px;
  z-index:1001;
}

.icon {
  width: 25px;
  height: 25px;
  cursor: pointer;

  opacity:0.3;

  user-select: none;

  transition: opacity .4s ease-in-out;
}

.icon:hover {
  opacity: 1;
}

@media (hover:none){
.icon{scale:0.8;}
}
