
.content-hidden {
  opacity: 0;
}

.content-visible {
  opacity: 1;
}

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  position: relative;
  z-index: 1;
  transition: opacity 1s ease-in-out;
}



.centre-button {
  font-size: 32px;
  font-family: 'Dihjauti';
  text-align: center;
  letter-spacing: 15px;
  text-transform: uppercase;
  line-height: 1.5;
  color:#000;
  
  background: linear-gradient(
    90deg, 
    rgba(118,118,118,0.5) 0%,
    rgba(255,255,255,1) 50%,
    rgba(118,118,118,0.5) 100%
    );
    
  background-size: 200% 200%;
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 3.5px transparent;

  margin:0;
  width: 100vh;
  
  padding-top:15px;

  position: absolute; 
  top: 45vh; 
  width: 100%;

  z-index: 1;
  animation: pulse 2s infinite;
  transition: color 0.5s ease-in-out, transform 0.5s ease-in-out;

  user-select: none; /* предотвращает выделение текста */
}

.centre-button::before{
  content: "";
  position: absolute; top: 0vh; width: 100vh;
  height: 100%;
  width: 100%;
  left:0%;

  border-top:1px dotted;
  border-bottom:1px dotted;
  border-image: linear-gradient(
    to right, 
    rgba(118,118,118,0.5) 0%,
    rgba(255,255,255,1) 50%,
    rgba(118,118,118,0.5) 100%) 1;
 
  background-image: linear-gradient(
    45deg, 
    rgba(0,42,255,0) 0%,
    rgba(165, 165, 165, 0.5) 50%,
    rgba(0,42,255,0) 100%
    );

  z-index: -1;
  transition: background-image 0.5s ease-in-out;
}

.centre-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: radial-gradient(
    circle at var(--x, 50%) var(--y, 50%),
    rgba(255, 0, 98, 0.2)  0%, 
    rgb(255, 48, 117) 50%, 
    rgba(255, 0, 98, 0.2) 100%
    );

  z-index: -2;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;
}


.centre-button:hover::after {
  opacity: 1;
}

.centre-button:hover {

  background: linear-gradient(
    45deg, 
    rgba(0,42,255,0) 0%,

    rgba(205, 0, 26, 0.5) 50%,

    rgba(0,42,255,0) 100%
    );

  color:#ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: none;
  transform: scale(1.025);
  text-shadow: 0 0 5px #00000070, 0 0 10px #fff, 0 0 15px #00000070;
}


button {
  color: #ffffff;
  
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 0;
}

.text-container{
  position: fixed;
  top: 60%; 
  left: 50%;
  transform: translateX(-50%);

  display:flex;
  justify-content: center;

  width: 420px;
  height: 20vh;
  user-select: none;
}

.text-under{
  color: rgb(70, 70, 70);
  text-align: justify;
  font-size: 18px;
  text-align-last: justify;
  letter-spacing: 2px;

  width: 100%;
  height: 100%;

}

p span{
  width: 100%; 
  display: inline-block;
  
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}



@media (hover: none) {
  .centre-button {
    font-size: 22px; /* Еще больше уменьшаем размер шрифта */
    letter-spacing: 8px; /* Уменьшаем межбуквенный интервал */
    width: 100vw; /* Устанавливаем ширину кнопки в зависимости от ширины экрана */
    -webkit-text-stroke: 2px transparent;
    top: 40vh; 
  }

  .text-container{
    width: 70vw;
    height: 20vh;
    top: 60%; 
  }

  .text-under{
    width: 100%;
    height: 100%;
    font-size: 12px;
  }


}

@media (max-width: 768px) and (pointer: fine) {
  .centre-button {
    font-size: 28px; /* Еще больше уменьшаем размер шрифта */
    letter-spacing: 10px; /* Уменьшаем межбуквенный интервал */
    width: 100vw; /* Устанавливаем ширину кнопки в зависимости от ширины экрана */
    -webkit-text-stroke: 2px transparent;
  }
}

@media (max-height: 612px) and (pointer: fine){
  .text-under{
    display: none;
  }

}

@media (max-height: 512px) and (hover:none){
  .text-under{display:none};
}


