/* src/components/css/agreementModal.css */
.agreement-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: rgb(32, 32, 32);
  padding: 15px;
  border-radius: 10px;
  max-width: 65vw;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  outline: none;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  box-sizing: border-box;
  z-index:1001;

  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  visibility: hidden;

}

.agreement-modal.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;


}

.agreement-modal.hide {
  
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

}


.agreement-modal-overlay {
  background: rgba(255, 255, 255, 0.75);
  transition: all 0.150s;
  z-index:1001;

}

.modal-content {
  user-select: none;
  max-height: calc(65vh - 120px);
  overflow-y: auto;
  text-align: justify;
  padding: 10px;
  width: 100%;
  overflow-x:hidden;
  line-height: 1.5;
  flex-grow: 1;
  box-sizing: border-box;
 
  max-height: 60vh;
  overflow-y: auto;

  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #ff6593 #ffffff00; /* Для Firefox */

  z-index:1001;

  

}

.modal-content h3 {
  color: #d3d3d3;
  margin-top: 20px;
  text-transform: uppercase;
  line-height: 2;

}
.modal-content p {
  margin: 10px 0;
  box-sizing: border-box;
}
.modal-content ul {
  line-height: 2;

}


.modal-content::-webkit-scrollbar {
  width: 8px; /* Ширина скроллбара для Webkit-браузеров */
}

.modal-content::-webkit-scrollbar-track {
  background: #ffffff00; /* Цвет трека скроллбара */
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #ff6593; /* Цвет ползунка скроллбара */
  border-radius: 10px; /* Радиус скругления углов ползунка */
  border: 3px solid #ffffff00; /* Отступы вокруг ползунка */
}

.close-button{
  font-family: 'Dihjauti', regular;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  align-self: center;

  transition: 
  background-color 0.2s ease-in-out, 
  scale 0.4s ease-in-out;

  
}

.close-button:hover {
  background-color: #ffffff10;
  scale:0.95;
  border:1px solid rgba(206, 206, 206, 0.4);
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  z-index: 1001;

}

@media (max-width: 768px) {
  .agreement-modal {
    max-width: 90vw;
    max-height: 80vh;
  }

}

@media (max-width: 480px) {
  .agreement-modal {
    max-width: 100vw;
    max-height: 100vh;
  }
  .modal-content {
    padding: 5px;
    font-size: 14px;
    width: 100%;
  }
}

@media (max-height: 384px) {
  .agreement-modal {
    max-height: 75vh;
  }
  .modal-content {
    font-size: 12px;
  }
}

@media (hover:none){
  .agreement-modal {
    max-width: 100vw;
    max-height: 75vh;
  }

}