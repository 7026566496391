
body {
  text-align: center;
  margin: 0;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000;
  color: #fff;
  overflow: hidden; /* Это важно для того, чтобы снежинки не прокручивались */
}

.form-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

}


.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%; /* Устанавливаем максимальную ширину */
  padding: 20px; /* Добавляем внутренние отступы */
  box-sizing: border-box; /* Учитываем отступы в размерах */
}

.form-background{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;


  border:1px solid;
  border-radius: 10px; /* Скругленные углы */
  border-color: transparent;

  background-color: transparent;
  max-width: 100%; /* Устанавливаем максимальную ширину */
  box-sizing: border-box; /* Учитываем отступы в размерах */

  overflow: hidden;

  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0px 0px 5px rgb(60, 138, 255); 

  animation: glowAnimation 2s infinite alternate ease-in-out;



}

@keyframes glowAnimation {
  0%, 100% {
    box-shadow: 
      0 0 var(--shadow-size) rgba(60, 138, 255, var(--intensity)),
      0 0 calc(var(--shadow-size) * 0.5) rgba(255, 255, 255, 0.3),
      0 0 calc(var(--shadow-size) * 1.5) rgba(0, 0, 255, 0.2);
  }
  50% {
    box-shadow: 
      0 0 calc(var(--shadow-size) * 1.2) rgba(60, 138, 255, 0.1),
      0 0 calc(var(--shadow-size) * 0.6) rgba(255, 255, 255, 0.2),
      0 0 calc(var(--shadow-size) * 1.8) rgba(0, 0, 255, 0.15);
  }
}

.form-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/form_bg_rect3.png') no-repeat center center;
  background-size: contain;

  z-index: -1;
  pointer-events: none; /* Чтобы изображение не мешало взаимодействию с формой */

  

}


.form-input {

  font-family: 'Dihjauti', regular;
  text-align: center;
  letter-spacing: 3px;

  line-height: 1.5;

  font-size: 20px;

  color:#000;
  background: linear-gradient(0deg, 
  rgba(118,118,118,0.5) 0%,
  rgba(255,255,255,1) 50%,
  rgba(118,118,118,0.5) 100%
  );

  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.5px transparent;


  border:none;
  border-radius: 5px 0 0 5px;
  
  margin-right: 10px; 

  appearance: none;
  -webkit-appearance: none; /* Убираем стрелки переключения в Safari и Chrome */
  -moz-appearance: textfield; /* Убираем стрелки переключения в Firefox */

  transition: all 0.3s ease-in-out;
  width: 100%;
  box-sizing: border-box; /* Учитываем отступы в размерах */

}

.currency-symbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  font-size:20px;

  color: rgb(90, 90, 90);

  opacity: 0;
  transition: opacity 0.3s ease-in-out;

}

.currency-symbol.visible {
  opacity: 1;
}

.currency-symbol.hidden {
  opacity: 0;
}


.form-input:focus {
  outline: none; /* Убираем белое выделение при фокусе */
  border-color: rgb(190, 211, 255); /* Изменение цвета границы при фокусе */
  box-shadow: 0 0 30px rgba(190, 211, 255, 0.1); /* Анимация или визуальный эффект при фокусе */
}

.form-input::placeholder {
  color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out; /* Плавная анимация для плейсхолдера */
}

.form-input:focus::placeholder {
  opacity: 0; /* Плавное исчезновение плейсхолдера при фокусе */
}


.separator{
  width: 10px; /* Ширина перегородки */
  height: 50px; /* Высота перегородки */
  background: url('./assets/form_bg_stripe3.png') no-repeat center center;
  background-size: contain;
  margin: 0 10px; /* Отступы для перегородки */
  flex-shrink: 0; /* Отключаем сжатие для перегородки */
  }

.input-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      
    }


.form-button {

  font-family: 'Dihjauti';

  text-align: center;
  
  letter-spacing: 3px;
  word-spacing: 10px;

  line-height: 1.5;


  font-size: 20px;

  color:#000000;
  background: linear-gradient(0deg, 
  rgba(118,118,118,0.5) 0%,
  rgba(255,255,255,1) 50%,
  rgba(118,118,118,0.5) 100%
  );

  background-size: 200% 200%;
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.5px transparent;

  cursor: pointer;
  z-index: 1;

  border-radius: 0 5px 5px 0;

  margin-left: 20px;

  box-sizing: border-box; /* Учитываем отступы в размерах */

  transition: opacity 0.5s ease-in-out, color .3s ease-in-out;

}


.form-button.invisible {
  opacity: 0.2; /* Почти невидимая кнопка */
  cursor: not-allowed; /* Изменение курсора для недоступной кнопки */
}

.form-button.visible {
  opacity: 1; /* Полностью видимая кнопка */
}

.form-button.amount-entered:hover {
  color:#ffffff;
  animation: stop;
}


.form-button.amount-entered {
  animation: pulse 1.5s infinite ease-in-out;

}

.content-hidden {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.content-visible {
  opacity: 1;
}

.preset-amounts {
  display: flex;
  justify-content: center;
  gap: 10px; /* Отступ между кнопками */
  width: 100%; /* Полная ширина контейнера */
  position: relative; /* Относительное позиционирование */
  margin-top: 20px;
}

.preset-amount-button {
  font-family: 'Dihjauti', regular;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.5;
  font-size: 16px;

  color: #000;
  background: linear-gradient(0deg, 
  rgba(118,118,118,0.5) 0%,
  rgba(255,255,255,1) 50%,
  rgba(118,118,118,0.5) 100%
  );

  background-size: 150% 150%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.5px transparent;

  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;

  opacity: 0.5;

  transition: background-color 0.3s ease-in-out, 
  transform 0.3s ease-in-out,
  opacity .3s ease-in-out;
}

.preset-amount-button:hover {
  opacity: 1;
  transform: scale(1.05);
}

.preset-amount-button:focus {
  outline: none;
  opacity: .8;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@keyframes fadeInOut-payment {
0%, 100% { 
  opacity: 0,
}
50% { 
  opacity: 0.3; 
}
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.015);
  }
  100% {
    transform: scale(1);
  }
}

/* Убираем стрелки переключения в браузерах на основе WebKit */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Убираем стрелки переключения в Firefox */
input[type=number] {
appearance: textfield;
-moz-appearance: textfield;
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {

  .form-container {
    flex-direction: column;
    width: 100%; /* Устанавливаем ширину в 100% */
    padding: 2px; /* Добавляем отступы */
    background: rgba(255, 255, 255, 0.05); /* Слегка заметный фон */
    border: 1px dotted;
    border-color:rgba(255, 255, 255, 0.1);

  }

  .form-input {
    font-size: 20px; /* Уменьшаем размер шрифта */
    width: 100%; /* Устанавливаем ширину с учетом отступов */
    margin-right: 0px;
    margin-top: 10px;
  }

  .currency-symbol{
    margin-top:10px;
  }

  .form-input:focus{
      top:20px;
      padding-top:5px;
      width:100%;
  }

  .form-button {
    font-size: 20px; /* Уменьшаем размер шрифта */
    width: 100%; /* Устанавливаем ширину с учетом отступов */
    margin-left: 0px;
    margin-top: 5px;
  }

  .separator {
      display: none;
  }
  
  .form-background{
      width: 100%;
  }

  .form-background::before {
    display: none;

  }

  .input-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
    }
}

@media (max-width: 480px) {
  .form-input {
    font-size: 16px; /* Уменьшаем размер шрифта */
    width: 100%;
  }

  .form-button {
    font-size: 16px; /* Уменьшаем размер шрифта */
    
  }

  
}

@media (hover:none){
  .form-container{
    position: fixed;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 

    flex-direction: column;
  }

  .form-input{font-size:20px;}

  .form-button{font-size:20px;}

  .preset-amount-button{font-size:12px;}

  .preset-amounts{
    position:fixed;
    top: 60%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
  }

  .currency-symbol{font-size:16px; top:45%; }
}

@media (max-height:512px) and (hover:none){
  .preset-amounts{display:none;}
}

@media (max-width:400px) and (hover:none){
  .preset-amount-button{font-size:10px;}
}

@media(max-width:350px) and (hover:none){
  .preset-amounts{display:none;}
}