/* src/components/Footer.css */
.footer {
  font-family: 'Dihjauti', regular;
   position: fixed;
   bottom: 0; 
   left: 50%;
   transform: translateX(-50%);
   flex-wrap: nowrap; /* Prevent wrapping */
   z-index: 10;
   padding: 10px 0;
 }

.footer-text{
   letter-spacing: 10px;
   text-transform: uppercase;

   font-size: 20px;

   letter-spacing: 10px;
   text-transform: uppercase;

   color:#000;
   background: linear-gradient(45deg, rgba(118,118,118,0.5) 0%,rgba(255,255,255,1) 50%,rgba(118,118,118,0.5) 100%);
   background-size: 150% 150%;
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-stroke: 1px transparent;

   animation: glow 3s infinite alternate; /* Анимация изменения цвета и мерцания */
   margin-bottom: 20px;

   user-select: none;
}

.footer-container{
 width: 300px;

 display:flex;
 justify-content: center;
 flex-direction: column;
 align-items: center;
}


.footer-links {
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 color:#666666;
 font-size: 10px;
 letter-spacing: 2px;
 z-index: 5;
 overflow: hidden;

 max-height: 0;
 visibility: hidden;
 transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.footer-links a {
 color:#666666;
 text-align: justify;
 text-align-last: justify;
 text-decoration: none;
}

.footer-links p {
 margin: 0 0 2px 0; /* Уменьшаем нижнее поле для текста */
}

.footer-links a:hover {
 text-decoration: underline;
}

.footer-links .contact-link {
 margin-left: 10px; /* Добавляем отступ только к ссылке "Контакты" */
}

.footer-span{
 width: 100%; 
 display: inline-block;
}

.footer-links.expanded {
 max-height: 500px; 
 visibility: visible;
}

.expand-button {
 background: none;
 border: 1px solid #242424;
 border-radius: 5px;
 color: #666666;
 cursor: pointer;
 font-size: 10px;
 margin-top: 5px;
 margin-bottom: 10px;
 transition: all 0.2s ease-in-out;
 user-select: none;
}


.expand-button:hover{
 color:#999999;
 border-radius: 15px;
 border-color:#999999;
}

@media (hover: none) {
 .footer-text {
   font-size: 14px; /* Уменьшаем размер шрифта на планшетах */
 }

 .footer-links{
   font-size:8px;
   
 }

 .footer-links p {
   margin: 0 0 0.5px 0; /* Уменьшаем нижнее поле для текста */
 }

 .expand-button:hover {
   color: #666666;
   border-radius: 5px;
   border-color: #242424;
 }

 .expand-button:active {
   color: #999999;
   border-radius: 15px;
   border-color: #999999;
 }


}

@media (max-height: 384px) {
 .footer-text {
   display:none;
   
 }

 .footer-links{
   font-size:8px;
 }


}