@font-face {
  font-family: 'Dihjauti';
  src: url('../fonts/Dihjauti-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Revard';
  src: url('../fonts/Revard.otf') format('opentype');
}

body {
  font-family: 'Dihjauti';
  text-align: center;
  margin: 0;
  height: 100vh;
  position: relative;
  background-color: #000;
  color: #fff;
  overflow: hidden; 
  touch-action: none; 
}

* {
  box-sizing: border-box;
}


