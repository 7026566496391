.header {
    position: fixed;
    top: 35px; 
    left: 50%;
    transform: translateX(-50%);

    font-family: 'Revard';

    letter-spacing: 10px;
    text-transform: uppercase;
    color: #fff;
    z-index: 1;
    opacity: 0.4;
    font-size: 20px;

    letter-spacing: 5px;
    text-transform: uppercase;

    color:#000;
    background: linear-gradient(15deg, rgba(118,118,118,0.5) 0%,rgba(255,255,255,1) 50%,rgba(118,118,118,0.5) 100%);
    background-size: 150% 150%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 1px transparent;

    
  }


  @media (hover: none) {
    .header {
      font-size: 16px; /* Уменьшаем размер шрифта на планшетах */
    }
  }

  @media (max-height: 384px) {
    .header{
      font-size: 14px;
      
    }
  
  }
  