.admin-panel {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px;

    background-color: transparent;
 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .admin-panel .stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .admin-panel .stats p {
    font-size: 1.2em;
    margin: 5px 0;
    color: #adadad;
  }
  
  .admin-panel .stats p span {
    font-weight: bold;
    color: #333;
  }
  
  .admin-panel button {
    font-family: Dihjauti;

    padding: 10px 20px;
    font-size: 1em;
    margin:5px;

    color: #adadad;
    background-color: transparent;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 5px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .admin-panel button:hover {
    border-color: #285f66;
  }

  .donations-table-wrapper {
    overflow-x: auto;
    margin-top: 20px;
  }
  
  .donations-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .donations-table th,
  .donations-table td {
    border: 1px solid rgb(66, 66, 66);
    padding: 8px;

    color: #adadad;

  }


  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent;
  }
  
  .login-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: transparent;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form input {
    margin: 10px 0;
    padding: 10px;
    color: #adadad;
    background: transparent;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 4px;
    width: 100%;
  }
  
  .login-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(66, 66, 66);
    color: #adadad;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-form button:hover {
    background-color: #285f66;
  }


  