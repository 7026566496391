.agreement-popup-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.4s ease-in-out;
}

.agreement-popup-overlay.show {
  opacity: 1;
  visibility: visible;
}

.agreement-popup-overlay.hide {
  opacity: 0;
  visibility: hidden;
}

.agreement-popup {
  background: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  width: 100%;
  text-align: center;
}

.modal-popup-content{

  max-height: calc(65vh - 120px);
  overflow-y: auto;
  text-align: justify;
  padding: 10px;
  width: 100%;
  overflow-x:hidden;
  line-height: 1.5;
  flex-grow: 1;
  box-sizing: border-box;
  z-index:10;
}

.modal-popup-content h3 {
  color: #d3d3d3;
  margin-top: 20px;
  text-transform: uppercase;
  line-height: 2;

}
.modal-popup-content p {
  margin: 10px 0;
  box-sizing: border-box;
}
.modal-popup-content ul {
  line-height: 2;

}

.agreement-content {
  max-height: 60vh;
  overflow-y: auto;
  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #ff6593 #ffffff00; /* Для Firefox */
}

.agreement-content::-webkit-scrollbar {
  width: 8px; /* Ширина скроллбара для Webkit-браузеров */
}

.agreement-content::-webkit-scrollbar-track {
  background: #ffffff00; /* Цвет трека скроллбара */
}

.agreement-content::-webkit-scrollbar-thumb {
  background-color: #ff6593; /* Цвет ползунка скроллбара */
  border-radius: 10px; /* Радиус скругления углов ползунка */
  border: 3px solid #ffffff00; /* Отступы вокруг ползунка */
}


.agreement-popup-buttons {

  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.agreement-buttons button {
  font-family: 'Dihjauti', regular;
  text-transform: uppercase;

  margin: 10px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: black;


  transition: 
  background-color 0.2s ease-in-out, 
  scale 0.4s ease-in-out;

  
}


.accept-button:hover{
  background-color: rgb(0, 85, 35);
  scale:1.05;
  border:1px solid rgba(119, 255, 175, 0.4);

}


.decline-button:hover{
  background-color: rgb(85, 0, 35);
  scale:1.05;
  border:1px solid rgba(255, 119, 175, 0.4);

}
