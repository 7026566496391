#snowflakes-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Это позволяет элементам под снежинками быть кликабельными */
  z-index: -2; /* Устанавливаем более низкий z-index для контейнера со снежинками */
  overflow: hidden;
}

.snowflake {
  position: absolute;
  top: -10px;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
  animation: fall linear infinite, fade linear infinite;
  will-change: transform, opacity;

  filter: blur(1px);

 
}

@keyframes fall {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(100vh) translateX(var(--end-x));
  }
}



@keyframes fade {
  0% {
    opacity: 0.55;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
}
