.progress-container-wrapper {
    position: fixed; 
    display: flex;
    justify-content: center;
    bottom: 100px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
}

.progress-container {
    position: relative; 
    width: 50vw;
    height: 15px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 20px;
    overflow: hidden;
    background: transparent;
    
}

.progress-particle {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    background-color: rgb(190, 211, 255);
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    box-shadow: 0 0 2px rgba(255, 255, 255, 1), 0 0 4px rgba(255, 255, 255, 1); /* добавляем свечение */
    animation: flyAround linear infinite, fadeInOut 2s infinite;
    will-change: transform, opacity;
}

.tooltip {
    position: absolute;
    top: -30px; /* Позиция над прогресс-баром */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: transparent;
    color: #afafaf;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 14px;
    opacity:0;
    transition: opacity 0.4s ease-in-out;
    pointer-events: none;

}

.tooltip.visible {
    opacity: 1;
}

@keyframes flyAround {
    0% {
        transform: translateY(5%);
    }
    50% {
        transform: translateY(50%);
    }
    100% {
        transform: translateY(5%);
    }
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@media (max-height: 512px) and (pointer: fine) {
    .progress-container-wrapper {
        bottom: 100px;
    }
}

@media (max-height: 386px) and (pointer: fine){
    .progress-container-wrapper {
        display:none;
    }
}

@media (hover:none){
    .tooltip{font-size:10px;}
}

@media (hover:none) and (max-height: 312px){
    .progress-container-wrapper {
        display:none;
    }
}