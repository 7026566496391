.conclusion-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.thanks-text{
  font-family: 'Dihjauti', regular;
  text-align: center;
  
  letter-spacing: 15px;
  line-height: 1.5;
  font-size: 32px;

  color:#000;
  background: linear-gradient(0deg, rgba(118,118,118,0.5) 0%,rgba(255,255,255,1) 50%,rgba(118,118,118,0.5) 100%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;

  user-select: none;
  z-index:1001;
}

.content-hidden {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.content-visible {
  opacity: 1;
}

@media (max-width: 768px) {
  .thanks-text{
      color:#000;
      background: linear-gradient(10deg, rgba(118,118,118,0.5) 0%,rgba(255,255,255,1) 50%,rgba(118,118,118,0.5) 100%);
      background-size: 200% 200%;

      background-clip:text;
      -webkit-background-clip: text;
      -webkit-text-stroke: 2px transparent;
  }
}

